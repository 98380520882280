import React from "react"
import Page from "../components/page.js"

export default () => {
  return (
    <Page>
      <div>email: boraerden1@gmail.com</div>
      <div>
        twitter:{" "}
        <a
          href="https://twitter.com/bora__erden"
          target="_blank"
          rel="noreferrer"
        >
          @bora__erden
        </a>
      </div>
    </Page>
  )
}
